const dateTimeSqlToDateTimeBR = (value) => {
  const date = new Date(value)
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }
  return date.toLocaleDateString('pt-BR', options)
}

const dateTimeSqlToDateBR = (value) => {
  const date = new Date(`${value} 00:00:00`)
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  return date.toLocaleDateString('pt-BR', options)
}

export { dateTimeSqlToDateTimeBR, dateTimeSqlToDateBR }
