<template>
  <CreateEditFluxoModal
    v-if="showCreateEditFluxoModal"
    @closeModal="showCreateEditFluxoModal = false"
  />
  <div class="card">
    <div class="card-header d-flex align-items-center py-0">
      <h4 class="py-3 mb-0">Fluxos</h4>
      <div class="ms-auto my-auto">
        <button
          type="button"
          class="btn btn-primary"
          title="Cadastrar Fluxo"
          @click="showCreateEditFluxoModal = true"
        >
          Cadastrar Fluxo
        </button>
      </div>
    </div>
    <LoadingData v-if="isLoadingData" class="my-3" />
    <ListFluxos v-else :fluxos="fluxos" />
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditFluxoModal from '@/components/admin/fluxos/fluxos/CreateEditFluxoModal.vue'
import ListFluxos from '@/components/admin/fluxos/fluxos/ListFluxos.vue'

export default {
  name: 'Fluxos',

  components: {
    CreateEditFluxoModal,
    LoadingData,
    ListFluxos,
  },

  data() {
    return {
      isLoadingData: true,
      showCreateEditFluxoModal: false,
      fluxos: [],
    }
  },

  methods: {
    async getFluxos() {
      try {
        this.fluxos = await this.$store.dispatch('getFluxos')
        this.isLoadingData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getFluxos()
  },
}
</script>
