<template>
  <DetailsFluxo :idFluxo="idFluxo" />
  <EtapasFluxo :idFluxo="idFluxo" />
</template>

<script>
import DetailsFluxo from '@/components/admin/fluxos/fluxos/DetailsFluxo.vue'
import EtapasFluxo from '@/components/admin/fluxos/etapas/EtapasFluxo.vue'

export default {
  name: 'FluxoDetails',

  components: {
    DetailsFluxo,
    EtapasFluxo,
  },

  data() {
    return {
      idFluxo: this.$route.params.idFluxo,
    }
  },
}
</script>
