<template>
  <table class="table table-striped table-sm table-bordered" :id="dataTable.id">
    <thead>
      <tr>
        <th class="text-center">#</th>
        <th class="text-center">Código</th>
        <th class="text-center">CBO</th>
        <th>Nome</th>
        <th>Descrição</th>
        <th class="text-center">Status</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="funcao in funcoes" :key="funcao.IDFUNCAO">
        <td class="text-center">{{ funcao.IDFUNCAO }}</td>
        <td class="text-center">{{ funcao.CODIGO }}</td>
        <td class="text-center">{{ funcao.CBO }}</td>
        <td>{{ funcao.NOME }}</td>
        <td>{{ funcao.DESCRICAO }}</td>
        <td v-html="statusTranslate(funcao.STATUS)" class="text-center" />
        <td class="text-center p-2">
          <button
            type="button"
            class="btn btn-outline-danger btn-icon"
            title="Excluir"
            @click="$emit('deleteFuncao', funcao.IDFUNCAO)"
          >
            <i class="ph ph-trash" />
          </button>
          <button
            type="button"
            class="btn btn-outline-warning btn-icon ms-2"
            title="Editar"
            @click="$emit('createEditFuncao', funcao.IDFUNCAO)"
          >
            <i class="ph ph-pencil" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import '@/utils/basicDataTableExtend'
import { statusTranslate } from '@/utils/strings'

export default {
  name: 'ListFuncoes',

  props: {
    funcoes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      table: null,
      dataTable: {
        id: 'dataTableListFuncoes',
        columnDefs: [
          {
            targets: [5, 6],
            orderable: false,
          },
          {
            targets: [6],
            width: 130,
          },
        ],
      },
      statusTranslate,
    }
  },

  methods: {
    createDataTable() {
      if (this.table) {
        this.table.destroy()
      }
      this.table = window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
