<template>
  <CreateEditFuncaoModal
    v-if="showCreateEditFuncaoModal"
    :idFuncao="idFuncaoSelected"
    @closeModal="closeAllModal"
  />
  <DeleteFuncaoModal
    v-if="showDeleteFuncaoModal"
    :idFuncao="idFuncaoSelected"
    @closeModal="closeAllModal"
  />
  <div class="card">
    <div class="card-header d-flex align-items-center py-0">
      <h4 class="py-3 mb-0">Funções</h4>
      <div class="ms-auto my-auto">
        <button
          type="button"
          class="btn btn-primary"
          title="Cadastrar Função"
          @click="createEditFuncao()"
        >
          Cadastrar Função
        </button>
      </div>
    </div>
    <LoadingData v-if="isLoadingData" class="my-3" />
    <ListFuncoes
      v-else
      :funcoes="funcoes"
      @createEditFuncao="createEditFuncao"
      @deleteFuncao="deleteFuncao"
    />
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditFuncaoModal from '@/components/admin/funcoes/CreateEditFuncaoModal.vue'
import DeleteFuncaoModal from '@/components/admin/funcoes/DeleteFuncaoModal.vue'
import ListFuncoes from '@/components/admin/funcoes/ListFuncoes.vue'

export default {
  name: 'Funcoes',

  components: {
    CreateEditFuncaoModal,
    DeleteFuncaoModal,
    LoadingData,
    ListFuncoes,
  },

  data() {
    return {
      isLoadingData: true,
      showCreateEditFuncaoModal: false,
      showDeleteFuncaoModal: false,
      funcoes: [],
      idFuncaoSelected: null,
    }
  },

  methods: {
    async getFuncoes() {
      try {
        this.funcoes = await this.$store.dispatch('getFuncoes')
        this.isLoadingData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditFuncao(idFuncao) {
      this.idFuncaoSelected = idFuncao || null
      this.showCreateEditFuncaoModal = true
    },

    deleteFuncao(idFuncao) {
      this.idFuncaoSelected = idFuncao
      this.showDeleteFuncaoModal = true
    },

    closeAllModal(needReload) {
      if (needReload) {
        this.getFuncoes()
      }
      this.showCreateEditFuncaoModal = false
      this.showDeleteFuncaoModal = false
    },
  },

  mounted() {
    this.getFuncoes()
  },
}
</script>
