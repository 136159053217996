<template>
  <table class="table table-striped table-sm table-bordered" :id="dataTable.id">
    <thead>
      <tr>
        <th class="text-center">#</th>
        <th>Unidade</th>
        <th>Tipo de Movimentação</th>
        <th>Nome</th>
        <th class="text-center">Status</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="fluxo in fluxos" :key="fluxo.IDFLUXO">
        <td class="text-center">{{ fluxo.IDFLUXO }}</td>
        <td>{{ fluxo.NOMEUNIDADE }}</td>
        <td>{{ fluxo.NOMETIPOMOVIMENTACAO }}</td>
        <td>{{ fluxo.NOME }}</td>
        <td v-html="statusTranslate(fluxo.STATUS)" class="text-center" />
        <td class="text-center p-2">
          <router-link
            :to="`fluxos/${fluxo.IDFLUXO}`"
            class="btn btn-outline-primary btn-icon"
            title="Detalhes"
          >
            <i class="ph ph-file-text" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import '@/utils/basicDataTableExtend'
import { statusTranslate } from '@/utils/strings'

export default {
  name: 'ListFluxos',

  props: {
    fluxos: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      table: null,
      dataTable: {
        id: 'dataTableListFluxos',
        columnDefs: [
          {
            targets: [4, 5],
            orderable: false,
          },
          {
            targets: [5],
            width: 130,
          },
        ],
      },
      statusTranslate,
    }
  },

  methods: {
    createDataTable() {
      if (this.table) {
        this.table.destroy()
      }
      this.table = window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
