<template>
  <div id="modalCreateEditUnidade" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ textModal }} Unidade</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-sm-6">
                <label class="form-label">
                  Código Coligada: <span class="text-danger">*</span>
                </label>
                <input
                  type="number"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.unidade.CODCOLIGADA.$errors.length,
                  }"
                  min="1"
                  step="1"
                  placeholder="Código da Coligada"
                  v-model="v$.unidade.CODCOLIGADA.$model"
                />
                <span
                  v-if="
                    v$.unidade.CODCOLIGADA.required.$invalid &&
                    v$.unidade.CODCOLIGADA.$errors.length
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  Código da Filial: <span class="text-danger">*</span>
                </label>
                <input
                  type="number"
                  class="form-control"
                  :class="{ 'is-invalid': v$.unidade.CODFILIAL.$errors.length }"
                  min="1"
                  step="1"
                  placeholder="Código da Filial"
                  v-model="v$.unidade.CODFILIAL.$model"
                />
                <span
                  v-if="
                    v$.unidade.CODFILIAL.required.$invalid &&
                    v$.unidade.CODFILIAL.$errors.length
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  Nome: <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.unidade.NOME.$errors.length }"
                  placeholder="Nome da Unidade"
                  v-model="v$.unidade.NOME.$model"
                />
                <span
                  v-if="
                    v$.unidade.NOME.required.$invalid &&
                    v$.unidade.NOME.$errors.length
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  Status: <span class="text-danger">*</span>
                </label>
                <select class="form-select" v-model="v$.unidade.STATUS.$model">
                  <option value="" disabled>Selecione</option>
                  <option
                    v-for="option in statusOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :title="textModal"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph ph-spinner spinner" />
              </span>
              <span v-else>{{ textModal }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'CreateEditUnidadeModal',

  props: {
    idUnidade: {
      type: [String, Number],
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      unidade: {
        CODCOLIGADA: null,
        CODFILIAL: null,
        NOME: '',
        STATUS: 1,
      },
      statusOptions: [
        { value: 1, text: 'Ativo' },
        { value: 0, text: 'Inativo' },
      ],
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.idUnidade ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async getUnidade() {
      try {
        this.unidade = await this.$store.dispatch('getUnidade', this.idUnidade)
        showModal('modalCreateEditUnidade')
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          let response = null

          if (this.idUnidade) {
            response = await this.$store.dispatch('updateUnidade', {
              idUnidade: this.idUnidade,
              unidade: this.unidade,
            })
          } else {
            response = await this.$store.dispatch('createUnidade', this.unidade)
          }

          hideModal()
          this.$emit('closeModal', true)
          this.$root.$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      unidade: {
        CODCOLIGADA: {
          required,
        },
        CODFILIAL: {
          required,
        },
        NOME: {
          required,
        },
        STATUS: {
          required,
        },
      },
    }
  },

  mounted() {
    if (this.idUnidade) {
      this.getUnidade()
    } else {
      showModal('modalCreateEditUnidade')
    }
  },
}
</script>
