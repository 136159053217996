window.$.extend(window.$.fn.dataTable.defaults, {
  autoWidth: false,
  dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
  language: {
    emptyTable: 'Nenhum registro encontrado',
    info: 'Mostrando de _START_ até _END_ de _TOTAL_ registros',
    infoEmpty: 'Mostrando 0 até 0 de 0 registros',
    infoFiltered: '(Filtrados de _MAX_ registros)',
    infoThousands: '.',
    loadingRecords: 'Carregando...',
    processing: 'Processando...',
    zeroRecords: 'Nenhum registro encontrado',
    search:
      '<span class="me-2">Pesquisar:</span> <div class="form-control-feedback form-control-feedback-end flex-fill">_INPUT_<div class="form-control-feedback-icon"><i class="ph ph-magnifying-glass opacity-50"></i></div></div>',
    searchPlaceholder: 'Digite para filtrar...',
    paginate: {
      first: 'Primeiro',
      last: 'Último',
      next: document.dir === 'rtl' ? '&larr;' : '&rarr;',
      previous: document.dir === 'rtl' ? '&rarr;' : '&larr;',
    },
    lengthMenu: '<span class="me-2">Mostrar:</span> _MENU_',
  },
})
