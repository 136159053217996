import StatusMovimentacaoEnum from '@/enums/statusMovimentacaoEnum'
import { dateTimeSqlToDateTimeBR } from './date'

const createdUpdatedUser = (userName, date) => {
  return userName ? `${userName} em ${dateTimeSqlToDateTimeBR(date)}` : '-'
}

const statusTranslate = (value) => {
  const status = parseInt(value, 10)

  if (status === 0) {
    return '<span class="badge bg-danger">Inativo</span>'
  }
  if (status === 1) {
    return '<span class="badge bg-success">Ativo</span>'
  }
  return '<span class="badge bg-secondary">Indefinido</span>'
}

const statusMovimentacaoTranslate = (value) => {
  const status = parseInt(value, 10)

  if (StatusMovimentacaoEnum.REPROVADO === status) {
    return '<span class="badge bg-danger">Reprovado</span>'
  }
  if (StatusMovimentacaoEnum.AGUARDANDO_INICIO === status) {
    return '<span class="badge bg-yellow">Aguardando Início</span>'
  }
  if (StatusMovimentacaoEnum.APROVADO === status) {
    return '<span class="badge bg-teal">Aprovado</span>'
  }
  return '<span class="badge bg-secondary">Indefinido</span>'
}

export { createdUpdatedUser, statusTranslate, statusMovimentacaoTranslate }
