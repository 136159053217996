<template>
  <CreateEditEtapaModal
    v-if="showCreateEditEtapaModal"
    :idFluxo="idFluxo"
    :idEtapa="idEtapaSelected"
    :nextOrder="nextOrder"
    @closeModal="closeAllModal"
  />
  <DeleteEtapaModal
    v-if="showDeleteEtapaModal"
    :idFluxo="idFluxo"
    :idEtapa="idEtapaSelected"
    @closeModal="closeAllModal"
  />
  <LoadingData v-if="isLoadingData" />
  <div v-else>
    <div class="card">
      <div class="card-header d-flex align-items-center py-0">
        <h6 class="py-3 mb-0">Etapas do Fluxo</h6>
        <div class="ms-auto">
          <button
            type="button"
            class="btn btn-primary"
            title="Cadastrar Etapa"
            @click="createEditEtapa()"
          >
            Cadastrar Etapa
          </button>
        </div>
      </div>
      <div v-if="etapas.length == 0" class="card-body text-center">
        <p class="mb-0">Este fluxo não possui nehuma etapa cadastrada</p>
      </div>
    </div>
    <ListEtapas
      v-if="etapas.length > 0"
      :etapas="etapas"
      @editEtapa="createEditEtapa"
      @deleteEtapa="deleteEtapa"
    />
  </div>
</template>

<script>
import CreateEditEtapaModal from '@/components/admin/fluxos/etapas/CreateEditEtapaModal.vue'
import DeleteEtapaModal from '@/components/admin/fluxos/etapas/DeleteEtapaModal.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListEtapas from '@/components/admin/fluxos/etapas/ListEtapas.vue'

export default {
  name: 'EtapasFluxo',

  components: {
    CreateEditEtapaModal,
    DeleteEtapaModal,
    LoadingData,
    ListEtapas,
  },

  props: {
    idFluxo: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      isLoadingData: true,
      showCreateEditEtapaModal: false,
      showDeleteEtapaModal: false,
      etapas: [],
      idEtapaSelected: null,
      nextOrder: 1,
    }
  },

  methods: {
    async getEtapas() {
      try {
        const response = await this.$store.dispatch('getEtapas', this.idFluxo)

        this.etapas = []

        response.forEach((etapa) => {
          this.etapas.push({
            ...etapa,
            showComponentes: false,
          })
        })

        this.nextOrder =
          this.etapas.length > 0
            ? Number(this.etapas[this.etapas.length - 1].ORDEM) + 1
            : 1

        this.isLoadingData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditEtapa(idEtapa) {
      this.idEtapaSelected = idEtapa || null
      this.showCreateEditEtapaModal = true
    },

    deleteEtapa(idEtapa) {
      this.idEtapaSelected = idEtapa
      this.showDeleteEtapaModal = true
    },

    closeAllModal(needReload) {
      if (needReload) {
        this.getEtapas()
      }
      this.showCreateEditEtapaModal = false
      this.showDeleteEtapaModal = false
    },
  },

  mounted() {
    this.getEtapas()
  },
}
</script>
