<template>
  <CreateEditFluxoModal
    v-if="showCreateEditFluxoModal"
    :idFluxo="idFluxo"
    @closeModal="closeAllModal"
  />
  <DeleteFluxoModal
    v-if="showDeleteFluxoModal"
    :idFluxo="idFluxo"
    @closeModal="closeAllModal"
  />
  <LoadingData v-if="isLoadingData" />
  <div v-else class="card">
    <div class="card-header d-flex align-items-center py-0">
      <h6 class="py-3 mb-0">Detalhes do Fluxo</h6>
      <div class="ms-auto">
        <router-link
          to="/admin/fluxos"
          class="btn btn-outline-dark"
          title="Listagem de Fluxos"
        >
          Listagem de Fluxos
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <p><strong>Nome:</strong> {{ fluxo.NOME }}</p>
        </div>
        <div class="col-md-6 col-sm-12">
          <p><strong>Uidade:</strong> {{ fluxo.NOMEUNIDADE }}</p>
        </div>
        <div class="col-md-6 col-sm-12">
          <p class="mb-0">
            <strong>Tipo de Movimentação:</strong>
            {{ fluxo.NOMETIPOMOVIMENTACAO }}
          </p>
        </div>
        <div class="col-md-6 col-sm-12">
          <p class="mb-0">
            <strong>Status: </strong>
            <span v-html="statusTranslate(fluxo.STATUS)" />
          </p>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-between align-items-center">
      <ul class="list-inline text-muted mb-0">
        <li class="list-inline-item">
          Cadastrado por:
          {{ createdUpdatedUser(fluxo.CRIADOPOR, fluxo.CRIADOEM) }}
        </li>
        <li class="list-inline-item">
          Atualizado por:
          {{ createdUpdatedUser(fluxo.ATUALIZADOPOR, fluxo.ATUALIZADOEM) }}
        </li>
      </ul>
      <div class="w-50 text-end">
        <button
          type="button"
          class="btn btn-outline-danger btn-icon"
          title="Excluir"
          @click="showDeleteFluxoModal = true"
        >
          <i class="ph ph-trash" />
        </button>
        <button
          type="button"
          class="btn btn-outline-warning btn-icon ms-2"
          title="Editar"
          @click="showCreateEditFluxoModal = true"
        >
          <i class="ph ph-pencil" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import { createdUpdatedUser, statusTranslate } from '@/utils/strings'
import CreateEditFluxoModal from '@/components/admin/fluxos/fluxos/CreateEditFluxoModal.vue'
import DeleteFluxoModal from '@/components/admin/fluxos/fluxos/DeleteFluxoModal.vue'

export default {
  name: 'DetailsFluxo',

  components: {
    CreateEditFluxoModal,
    DeleteFluxoModal,
    LoadingData,
  },

  props: {
    idFluxo: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      isLoadingData: true,
      showCreateEditFluxoModal: false,
      showDeleteFluxoModal: false,
      fluxo: {},
      createdUpdatedUser,
      statusTranslate,
    }
  },

  methods: {
    async getFluxo() {
      try {
        this.fluxo = await this.$store.dispatch('getFluxo', this.idFluxo)
        this.isLoadingData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }

        this.$router.push({ name: 'AdminFluxos' })
      }
    },

    closeAllModal(needReload) {
      if (needReload) {
        this.getFluxo()
      }
      this.showCreateEditFluxoModal = false
      this.showDeleteFluxoModal = false
    },
  },

  mounted() {
    this.getFluxo()
  },
}
</script>
