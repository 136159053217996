<template>
  <div id="modalDeleteFluxo" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Excluir Fluxo</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="deleteFluxo">
          <div class="modal-body pb-1">
            Tem certeza de que deseja excluir este fluxo?
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-danger"
              title="Excluir"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="ph ph-spinner spinner" />
              </span>
              <span v-else>Excluir</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'DeleteFluxoModal',

  props: {
    idFluxo: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      disableButton: false,
    }
  },

  methods: {
    async deleteFluxo() {
      this.disableButton = true
      try {
        const response = await this.$store.dispatch('deleteFluxo', this.idFluxo)

        hideModal()
        this.$emit('closeModal', false)
        this.$root.$refs.notification.success(response.message)

        this.$router.push({ name: 'AdminFluxos' })
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    showModal('modalDeleteFluxo')
  },
}
</script>
