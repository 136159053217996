<template>
  <h2 class="fw-bold text-primary">Área Administrativa</h2>
  <div class="row">
    <div class="col-lg-4 col-md-6 mb-3">
      <router-link to="/admin/unidades" title="Gestão de Unidades">
        <div
          class="card h-100 border-start border-start-width-3 border-start-primary"
        >
          <div
            class="card-body d-flex justify-content-between align-items-center py-1"
          >
            <h5 class="text-primary fw-bold mb-0">Unidades</h5>
            <i class="ph-arrows-clockwise ph-2x text-primary"></i>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-lg-4 col-md-6 mb-3">
      <router-link to="/admin/funcoes" title="Gestão de Funções">
        <div
          class="card h-100 border-start border-start-width-3 border-start-primary"
        >
          <div
            class="card-body d-flex justify-content-between align-items-center py-1"
          >
            <h5 class="text-primary fw-bold mb-0">Funções</h5>
            <i class="ph-arrows-clockwise ph-2x text-primary"></i>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-lg-4 col-md-6 mb-3">
      <router-link to="/admin/fluxos" title="Gestão de Fluxos">
        <div
          class="card h-100 border-start border-start-width-3 border-start-primary"
        >
          <div
            class="card-body d-flex justify-content-between align-items-center py-1"
          >
            <h5 class="text-primary fw-bold mb-0">Fluxos</h5>
            <i class="ph-calendar ph-2x text-primary"></i>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Admin',
}
</script>
