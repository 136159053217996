<template>
  <div id="modalCreateEditFuncao" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ textModal }} Função</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-sm-6">
                <label class="form-label">
                  Código: <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.funcao.CODIGO.$errors.length }"
                  placeholder="Código da Função"
                  v-model="v$.funcao.CODIGO.$model"
                />
                <span
                  v-if="
                    v$.funcao.CODIGO.required.$invalid &&
                    v$.funcao.CODIGO.$errors.length
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  CBO: <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.funcao.CBO.$errors.length }"
                  placeholder="CBO da Função"
                  v-model="v$.funcao.CBO.$model"
                />
                <span
                  v-if="
                    v$.funcao.CBO.required.$invalid &&
                    v$.funcao.CBO.$errors.length
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-12">
                <label class="form-label">
                  Nome: <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.funcao.NOME.$errors.length }"
                  placeholder="Nome da Função"
                  v-model="v$.funcao.NOME.$model"
                />
                <span
                  v-if="
                    v$.funcao.NOME.required.$invalid &&
                    v$.funcao.NOME.$errors.length
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-12">
                <label class="form-label">
                  Descrição: <span class="text-danger">*</span>
                </label>
                <textarea
                  class="form-control"
                  :class="{ 'is-invalid': v$.funcao.DESCRICAO.$errors.length }"
                  rows="4"
                  placeholder="Descrição da Função"
                  v-model="v$.funcao.DESCRICAO.$model"
                />
                <span
                  v-if="
                    v$.funcao.DESCRICAO.required.$invalid &&
                    v$.funcao.DESCRICAO.$errors.length
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">Faixa Salarial:</label>
                <input
                  type="number"
                  class="form-control"
                  min="0.01"
                  step="0.01"
                  placeholder="Faixa Salarial"
                  v-model="funcao.FAIXASALARIAL"
                />
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  Status: <span class="text-danger">*</span>
                </label>
                <select class="form-select" v-model="v$.funcao.STATUS.$model">
                  <option value="" disabled>Selecione</option>
                  <option
                    v-for="option in statusOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :title="textModal"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph ph-spinner spinner" />
              </span>
              <span v-else>{{ textModal }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'CreateEditFuncaoModal',

  props: {
    idFuncao: {
      type: [String, Number],
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      funcao: {
        CODIGO: '',
        CBO: '',
        NOME: '',
        DESCRICAO: '',
        FAIXASALARIAL: null,
        STATUS: 1,
      },
      statusOptions: [
        { value: 1, text: 'Ativo' },
        { value: 0, text: 'Inativo' },
      ],
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.idFuncao ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async getFuncao() {
      try {
        this.funcao = await this.$store.dispatch('getFuncao', this.idFuncao)
        showModal('modalCreateEditFuncao')
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          let response = null

          if (this.idFuncao) {
            response = await this.$store.dispatch('updateFuncao', {
              idFuncao: this.idFuncao,
              funcao: this.funcao,
            })
          } else {
            response = await this.$store.dispatch('createFuncao', this.funcao)
          }

          hideModal()
          this.$emit('closeModal', true)
          this.$root.$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      funcao: {
        CODIGO: {
          required,
        },
        CBO: {
          required,
        },
        NOME: {
          required,
        },
        DESCRICAO: {
          required,
        },
        STATUS: {
          required,
        },
      },
    }
  },

  mounted() {
    if (this.idFuncao) {
      this.getFuncao()
    } else {
      showModal('modalCreateEditFuncao')
    }
  },
}
</script>
