<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center h-100"
  >
    <p><i class="ph ph-spinner spinner" /></p>
    <p>Carregando dados ...</p>
  </div>
</template>

<script>
export default {
  name: 'LoadingData',
}
</script>
