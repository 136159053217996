<template>
  <div id="modalCreateEditFluxo" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ textModal }} Fluxo</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-sm-6">
                <label class="form-label">
                  Unidade: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid': v$.fluxo.IDUNIDADE.$errors.length,
                  }"
                  v-model="v$.fluxo.IDUNIDADE.$model"
                >
                  <option value="" disabled>Selecione</option>
                  <option
                    v-for="unidade in unidadesOptions"
                    :key="unidade.IDUNIDADE"
                    :value="unidade.IDUNIDADE"
                  >
                    {{ unidade.NOME }}
                  </option>
                </select>
                <span
                  v-if="
                    v$.fluxo.IDUNIDADE.required.$invalid &&
                    v$.fluxo.IDUNIDADE.$errors.length
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  Tipo de Movimentação: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid': v$.fluxo.IDTIPOMOVIMENTACAO.$errors.length,
                  }"
                  v-model="v$.fluxo.IDTIPOMOVIMENTACAO.$model"
                >
                  <option value="" disabled>Selecione</option>
                  <option
                    v-for="tipoMovimentacao in tipoMovimentacoesOptions"
                    :key="tipoMovimentacao.IDTIPOMOVIMENTACAO"
                    :value="tipoMovimentacao.IDTIPOMOVIMENTACAO"
                  >
                    {{ tipoMovimentacao.NOME }}
                  </option>
                </select>
                <span
                  v-if="
                    v$.fluxo.IDTIPOMOVIMENTACAO.required.$invalid &&
                    v$.fluxo.IDTIPOMOVIMENTACAO.$errors.length
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  Nome: <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.fluxo.NOME.$errors.length }"
                  placeholder="Nome da Fluxo"
                  v-model="v$.fluxo.NOME.$model"
                />
                <span
                  v-if="
                    v$.fluxo.NOME.required.$invalid &&
                    v$.fluxo.NOME.$errors.length
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-6">
                <label class="form-label">
                  Status: <span class="text-danger">*</span>
                </label>
                <select class="form-select" v-model="v$.fluxo.STATUS.$model">
                  <option value="" disabled>Selecione</option>
                  <option
                    v-for="option in statusOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :title="textModal"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph ph-spinner spinner" />
              </span>
              <span v-else>{{ textModal }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'CreateEditFluxoModal',

  props: {
    idFluxo: {
      type: [String, Number],
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      fluxo: {
        IDUNIDADE: '',
        IDTIPOMOVIMENTACAO: '',
        NOME: '',
        STATUS: 1,
      },
      unidadesOptions: [],
      tipoMovimentacoesOptions: [],
      statusOptions: [
        { value: 1, text: 'Ativo' },
        { value: 0, text: 'Inativo' },
      ],
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.idFluxo ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async getUnidadesAndTipoMovimentacoes() {
      try {
        this.unidadesOptions = await this.$store.dispatch('getUnidadesAtivas')
        this.tipoMovimentacoesOptions = await this.$store.dispatch(
          'getTipoMovimentacoes',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getFluxo() {
      try {
        this.fluxo = await this.$store.dispatch('getFluxo', this.idFluxo)
        showModal('modalCreateEditFluxo')
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          let response = null

          if (this.idFluxo) {
            response = await this.$store.dispatch('updateFluxo', {
              idFluxo: this.idFluxo,
              fluxo: this.fluxo,
            })

            hideModal()
            this.$emit('closeModal', true)
            this.$root.$refs.notification.success(response.message)
          } else {
            response = await this.$store.dispatch('createFluxo', this.fluxo)

            hideModal()
            this.$emit('closeModal', false)
            this.$root.$refs.notification.success(response.message)

            this.$router.push({
              name: 'AdminFluxosDetails',
              params: { idFluxo: response.data },
            })
          }
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      fluxo: {
        IDUNIDADE: {
          required,
        },
        IDTIPOMOVIMENTACAO: {
          required,
        },
        NOME: {
          required,
        },
        STATUS: {
          required,
        },
      },
    }
  },

  mounted() {
    this.getUnidadesAndTipoMovimentacoes()
    if (this.idFluxo) {
      this.getFluxo()
    } else {
      showModal('modalCreateEditFluxo')
    }
  },
}
</script>
