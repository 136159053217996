<template>
  <CreateEditUnidadeModal
    v-if="showCreateEditUnidadeModal"
    :idUnidade="idUnidadeSelected"
    @closeModal="closeAllModal"
  />
  <DeleteUnidadeModal
    v-if="showDeleteUnidadeModal"
    :idUnidade="idUnidadeSelected"
    @closeModal="closeAllModal"
  />
  <div class="card">
    <div class="card-header d-flex align-items-center py-0">
      <h4 class="py-3 mb-0">Unidades</h4>
      <div class="ms-auto my-auto">
        <button
          type="button"
          class="btn btn-primary"
          title="Cadastrar Unidade"
          @click="createEditUnidade()"
        >
          Cadastrar Unidade
        </button>
      </div>
    </div>
    <LoadingData v-if="isLoadingData" class="my-3" />
    <ListUnidades
      v-else
      :unidades="unidades"
      @createEditUnidade="createEditUnidade"
      @deleteUnidade="deleteUnidade"
    />
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditUnidadeModal from '@/components/admin/unidades/CreateEditUnidadeModal.vue'
import DeleteUnidadeModal from '@/components/admin/unidades/DeleteUnidadeModal.vue'
import ListUnidades from '@/components/admin/unidades/ListUnidades.vue'

export default {
  name: 'Unidades',

  components: {
    CreateEditUnidadeModal,
    DeleteUnidadeModal,
    LoadingData,
    ListUnidades,
  },

  data() {
    return {
      isLoadingData: true,
      showCreateEditUnidadeModal: false,
      showDeleteUnidadeModal: false,
      unidades: [],
      idUnidadeSelected: null,
    }
  },

  methods: {
    async getUnidades() {
      try {
        this.unidades = await this.$store.dispatch('getUnidades')
        this.isLoadingData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditUnidade(idUnidade) {
      this.idUnidadeSelected = idUnidade || null
      this.showCreateEditUnidadeModal = true
    },

    deleteUnidade(idUnidade) {
      this.idUnidadeSelected = idUnidade
      this.showDeleteUnidadeModal = true
    },

    closeAllModal(needReload) {
      if (needReload) {
        this.getUnidades()
      }
      this.showCreateEditUnidadeModal = false
      this.showDeleteUnidadeModal = false
    },
  },

  mounted() {
    this.getUnidades()
  },
}
</script>
