<template>
  <table class="table table-striped table-sm table-bordered" :id="dataTable.id">
    <thead>
      <tr>
        <th class="text-center">#</th>
        <th class="text-center">Código Coligada</th>
        <th class="text-center">Código Filial</th>
        <th>Nome</th>
        <th class="text-center">Status</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="unidade in unidades" :key="unidade.IDUNIDADE">
        <td class="text-center">{{ unidade.IDUNIDADE }}</td>
        <td class="text-center">{{ unidade.CODCOLIGADA }}</td>
        <td class="text-center">{{ unidade.CODFILIAL }}</td>
        <td>{{ unidade.NOME }}</td>
        <td v-html="statusTranslate(unidade.STATUS)" class="text-center" />
        <td class="text-center p-2">
          <button
            type="button"
            class="btn btn-outline-danger btn-icon"
            title="Excluir"
            @click="$emit('deleteUnidade', unidade.IDUNIDADE)"
          >
            <i class="ph ph-trash" />
          </button>
          <button
            type="button"
            class="btn btn-outline-warning btn-icon ms-2"
            title="Editar"
            @click="$emit('createEditUnidade', unidade.IDUNIDADE)"
          >
            <i class="ph ph-pencil" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import '@/utils/basicDataTableExtend'
import { statusTranslate } from '@/utils/strings'

export default {
  name: 'ListUnidades',

  props: {
    unidades: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      table: null,
      dataTable: {
        id: 'dataTableListUnidades',
        columnDefs: [
          {
            targets: [4, 5],
            orderable: false,
          },
          {
            targets: [5],
            width: 130,
          },
        ],
      },
      statusTranslate,
    }
  },

  methods: {
    createDataTable() {
      if (this.table) {
        this.table.destroy()
      }
      this.table = window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
