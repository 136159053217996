<template>
  <div
    v-for="etapa in etapas"
    :key="etapa.IDETAPA"
    class="card border-start border-start-secondary border-start-width-2"
  >
    <div class="card-header d-flex align-items-center py-0">
      <div>
        <h6 class="pt-3 mb-1 text-primary">
          {{ etapa.ORDEM }} - {{ etapa.NOME }}
        </h6>
        <p class="text-muted">{{ etapa.DESCRICAO }}</p>
      </div>
      <div class="ms-auto d-flex">
        <span
          role="button"
          class="badge bg-danger"
          title="Excluir Etapa"
          @click="$emit('deleteEtapa', etapa.IDETAPA)"
        >
          Excluir
        </span>
        <span
          role="button"
          class="badge bg-warning ms-2"
          title="Editar Etapa"
          @click="$emit('editEtapa', etapa.IDETAPA)"
        >
          Editar
        </span>
        <span
          role="button"
          class="badge bg-dark ms-2"
          title="Exibir/Ocultar Componentes"
          @click="etapa.showComponentes = !etapa.showComponentes"
        >
          Exibir Componentes
        </span>
      </div>
    </div>
    <div v-if="etapa.showComponentes" class="card-body">
      <p>Detalhes dos componentes aqui</p>
    </div>
    <div class="card-footer">
      <ul class="list-inline text-muted mb-0">
        <li class="list-inline-item">
          Cadastrado por:
          {{ createdUpdatedUser(etapa.CRIADOPOR, etapa.CRIADOEM) }}
        </li>
        <li class="list-inline-item">
          Atualizado por:
          {{ createdUpdatedUser(etapa.ATUALIZADOPOR, etapa.ATUALIZADOEM) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { createdUpdatedUser } from '@/utils/strings'

export default {
  name: 'ListEtapas',

  components: {},

  props: {
    etapas: {
      type: Array,
      required: true,
    },
  },

  emits: ['deleteEtapa', 'editEtapa'],

  data() {
    return {
      createdUpdatedUser,
    }
  },
}
</script>
